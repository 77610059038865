export const mfa = {
    bnppRefundMultifactorAuthStatusDisabled: 'bnppRefundMultifactorAuthStatusDisabled',
    bnppRefundMultifactorAuthStatusEnabled: 'bnppRefundMultifactorAuthStatusEnabled',
    MFADisabled: 'bnppMFADisabled',
    MFAEnabled: 'bnppMFAEnabled',
    DISABLE: 'DISABLE',
    ENABLE: 'ENABLE',
    DISABLESUCCESSMESSAGE: 'disableMFASuccess',
    DISABLESUCCESSSUBTITLE: 'disableMFASuccessSubtitle',
    ENABLESUCCESSMESSAGE: 'successTitle',
    ENABLESUCCESSSUBTITLE: 'successSubtitle',
    ENABLELOGINSUCCESSSUBTITLE: 'successLoginSubtitle',
    loginMFAStatusEnable: 'loginMFAStatusEnable',
    loginMFAStatusDisabled: 'loginMFAStatusDisabled',
    REFUNDS: 'REFUNDS',
    LOGIN: 'LOGIN',
    LOGINDISABLESUCCESSSUBTITLE: 'loginDisableSuccessSubtitle',
};
